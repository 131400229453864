import React from "react"
import { graphql } from "gatsby"
import widont from "widont"

import Password from "../components/password"
import ZeusModules from "../components/zeusModules"
import HeadingCta from "../components/headingCta"
import styles from "./private.module.scss"
import Layout, { zeusTheme } from "../components/layout"


export default props => {
  const secretPage = props.data.craft.secretPage
  const seo = secretPage.seomatic
  const slug = secretPage.slug

  return (
    <Layout seo={seo} hideBranding={secretPage.hideBranding} theme={zeusTheme}>
      <Password slug={slug}>
        {pageContent => {
          const entry = pageContent.data.secretPage
          return (
            <>
              <div className={`page-content-module ${styles.pageContent}`}>
                <div className="wrapper">
                  <div
                    className={`${styles.title} ${
                      entry.hideTitle ? "screen-reader-only" : ""
                    }`}
                  >
                    <div className="columns is-desktop">
                      <div className="column is-7-desktop is-offset-1-desktop">
                        <h1 className="h-1 mb-2">{widont(entry.title)}</h1>
                        <h2 className="body-large-after-tablet">
                          {widont(entry.subtitle)}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                {entry.zeusModules && <ZeusModules data={entry.zeusModules} />}
                <HeadingCta data={entry.headingCta[0]} />
              </div>
            </>
          )
        }}
      </Password>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    craft {
      secretPage: entry(slug: [$slug], section: "secretPages") {
        slug
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_secretPages_secretPage_Entry {
          hideBranding
        }
      }
    }
  }
`
